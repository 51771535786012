import * as React from 'react'

import Col from '../components/Col'
import Title from '../components/Title'
import Gap from '../components/Gap'
import Button from '../components/Button'
import Link from '../components/Link'
import SEO from '../components/SEO'
import { PageContent, PageWithBgImage } from '../components/Page'

const NotFoundPage = () => (
  <>
    <SEO title="Stránka nenalezena" />
    <PageWithBgImage blurred>
      <PageContent>
        <Col alignSelf="center" alignItems="center">
          <Gap gap="160px" />
          <Title as="h1" color="gold">
            Stránka nenalezena
          </Title>
          <Gap gap="32px" />
          <Link color="gold" to="/">
            <Button type="secondary">Jít na hlavní stránku</Button>
          </Link>
        </Col>
      </PageContent>
    </PageWithBgImage>
  </>
)

export default NotFoundPage
